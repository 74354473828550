.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.video-gradient {
  background-image: linear-gradient(transparent, #111);
  height: 50%;
  width: 100%;
  position: absolute;
  bottom: 0;
}

.cursive {
  font-family: 'Bodoni Moda';
  font-style: italic;
  font-weight: 800;
  -webkit-text-stroke: rgb(209, 213, 219) 1px;
  color: transparent;
}

.superbold-background {
  background-color: rgb(209, 213, 219);
  mix-blend-mode: difference;
  padding: 0 5px 0 5px;
}

.superbold {
  /* font-family: 'Hanuman'; */
  /* font-family: 'Bodoni Moda'; */
  font-weight: 900;
  color: black;
}

.emphasis {
  font-weight: bold;
  font-size: 20px;
  text-decoration: none;
  background-repeat: no-repeat;
  background-position: 120%, 122%, 0 130%;
  background-size: 100% 7px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
